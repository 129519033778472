/* eslint-disable @cspell/spellchecker -- kbbico */
export type VinconnectFlagNames =
  | "cardashboard.oemservices-dmc-to-stargate"
  | "nx.vinconnect.send-event-for-desking"
  | "nx.vinconnect.show-crw-deallist"
  | "nx.vinconnect.key-information.autotrader"
  | "nx.vinconnect.task-list.enable-test-page"
  | "oem.honda.disposition.update.enabled"
  | "nx.vinconnect.walkme"
  | "cardashboard.vinconnect.showblocklist-ui";

export type TestFlagNames = "devTestFlag";

type AllFlagsNames = TestFlagNames | VinconnectFlagNames;

type AppNames = "test" | "vinconnect";

type Flags = Array<{ name: AllFlagsNames; apps: Array<AppNames> }>;

const FLAGS: Flags = [
  /** Mock flag only for test page */
  {
    name: "devTestFlag",
    apps: ["test"]
  },
  {
    /** nx.vinconnect.send-event-for-desking
     * Sends an event on desking pages rather than refreshing */
    name: "nx.vinconnect.send-event-for-desking",
    apps: ["vinconnect"]
  },
  {
    name: "nx.vinconnect.show-crw-deallist",
    apps: ["vinconnect"]
  },
  {
    /** oem.honda.disposition.update.enabled
     * enables dmc manufacturer codes update for acura and Honda */
    name: "oem.honda.disposition.update.enabled",
    apps: ["vinconnect"]
  },
  {
    /** nx.vinconnect.key-information.autotrader
     * activates the AutoTrader banner */
    name: "nx.vinconnect.key-information.autotrader",
    apps: ["vinconnect"]
  },
  {
    /** nx.vinconnect.walkme
     * enaables walme injection script */
    name: "nx.vinconnect.walkme",
    apps: ["vinconnect"]
  },
  {
    /** nx.vinconnect.walkme
     * enables blocklist menu item in admin section */
    name: "cardashboard.vinconnect.showblocklist-ui",
    apps: ["vinconnect"]
  }
];

export const getActiveFlags = (appName: AppNames) => {
  return FLAGS.reduce((acc, { name, apps }, i) => {
    if (apps.some(app => app === appName)) {
      return {
        ...acc,
        [name]: true
      };
    } else {
      return acc;
    }
  }, {});
};
