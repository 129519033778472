import { useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import {
  TenantBridgeBar,
  getBridgeBarHeight
} from "@vinsolutions/tenant/bridge-bar";
import { useReactOidc } from "@axa-fr/react-oidc-context";
import { OidcUser } from "@vinsolutions/tenant/jwt-utils";
import { TenantBridgeBarProps } from "@vinsolutions/tenant/bridge-bar";
import { UpdateLdClient } from "./components/update-ld-client";
import {
  ModalIframe,
  SliderDrawerIframe
} from "@vinsolutions/ccrm/top-nav/modals";
import { useApp, useSyncCDWithBackForwardBtn } from "./useApp";
import { Header } from "@vinsolutions/ccrm/top-nav/main";
import { Routes } from "../router/routes";
import { useUpdateCarDashboardPath } from "@vinsolutions/ccrm/top-nav/main";
import { LDProvider } from "launchdarkly-react-client-sdk";
import { useHistoryPushCd } from "@vinsolutions/ccrm/top-nav/main";
import { getActiveFlags } from "@vinsolutions/core/third-party/launch-darkly";
import { RouteProvider } from "@vinsolutions/ccrm/top-nav/main";
import Logout from "./pages/logout/logout";
import { LogoutRedirect } from "./pages/logout-redirect";
import { routeList } from "@vinsolutions/ccrm/top-nav/route-config";
import { useCardashboardChannel } from "@vinsolutions/legacy-cardashboard/hooks";
import { BroadcastChannelNames } from "@vinsolutions/ccrm/constants";
import { createLogger } from "@vinsolutions/logger";
import { getConfig, getEnvironmentName } from "@vinsolutions/core/config";
import { Portals } from "@vinsolutions/feature/portals";

const logger = createLogger("VinConnect-App");
type StyledAppProps = {
  bridgeBarHeight: string;
};

const getBridgeBarProps = oidcUser => {
  logger.debug("Initializing getBridgeBarProps.");
  return {
    environment: getEnvironmentName(),
    odicUser: oidcUser as unknown as OidcUser,
    solutionGroupCode: "VIN"
  } as TenantBridgeBarProps;
};

const StyledApp = styled.div<StyledAppProps>`
  height: calc(100vh - ${props => props.bridgeBarHeight});
  display: grid;
  grid-template-columns: 100vw;
  grid-template-rows: min-content 1fr;
  grid-template-areas:
    "pageheader"
    "pagecontent";
  #connect-header {
    grid-area: pageheader;
    z-index: 1;
  }

  #cardashboardframe {
    height: 100%;
    margin-bottom: -7px;
    border: none;
  }
`;

const StyledRoutes = styled.div`
  height: 100%;
  overflow-y: auto;
  grid-area: pagecontent;
`;

const logUserOutTopNav = document.cookie.includes("LogUserOutTopNav=true");
logger.debug("logUserOutTopNav", logUserOutTopNav);
const reactOptions = { useCamelCaseFlagKeys: false };
const clientSideId = getConfig().launchDarklyId ?? "";
logger.debug(`LaunchDarkly clientId : ${clientSideId}`);
const flags = getActiveFlags("vinconnect");

export function App() {
  logger.debug("Initializing App.");
  const { pathname } = useLocation();
  const [forceRedirect, setForceRedirect] = useState(false);
  const { oidcUser } = useReactOidc();

  const bridgeBarProps = useMemo(() => {
    return getBridgeBarProps(oidcUser);
  }, [oidcUser]);

  const bridgeBarHeight = useMemo(() => {
    return getBridgeBarHeight(bridgeBarProps);
  }, [bridgeBarProps]);

  const { ldUser } = useApp();

  useCardashboardChannel(
    BroadcastChannelNames.topNavLogoutOtherTabs,
    BroadcastChannelNames.topNavLogoutOtherTabsContext,
    ev => {
      if (ev.data.tabContext !== window.tabContextId) {
        logger.debug("Received logout event from other tab.");
        setForceRedirect(true);
      }
    }
  );

  useUpdateCarDashboardPath();
  // TODO nx upgrade need to figure out alternative for useSyncCDWithBackForwardBtn
  // This function affects the direct entry of Vinconnect URLs.  If this function is removed then direct navigation by URL entry will not work
  useSyncCDWithBackForwardBtn();
  useHistoryPushCd();

  if (logUserOutTopNav) {
    logger.debug("LogUserOutTopNav cookie is set.");
    return <LogoutRedirect />;
  }

  if (pathname.indexOf("/logout") > -1 || forceRedirect) {
    logger.debug("Redirecting to logout page.");
    return <Logout />;
  }

  logger.debug("Rendering App.");
  logger.debug("Routes: ", routeList);
  return (
    <LDProvider
      clientSideID={clientSideId}
      context={ldUser}
      deferInitialization={true}
      flags={flags}
      reactOptions={reactOptions}
    >
      <UpdateLdClient />
      <Portals />
      <ModalIframe />
      <SliderDrawerIframe />
      <TenantBridgeBar
        environment={bridgeBarProps.environment}
        forceBridgeBar={bridgeBarProps.forceBridgeBar}
        odicUser={bridgeBarProps.odicUser}
        solutionGroupCode={bridgeBarProps.solutionGroupCode}
      />
      <StyledApp bridgeBarHeight={bridgeBarHeight}>
        <RouteProvider routeList={routeList}>
          <Header />
          <StyledRoutes>
            <Routes />
          </StyledRoutes>
        </RouteProvider>
      </StyledApp>
    </LDProvider>
  );
}

export default App;
