import styled from "styled-components";

export interface AutoTraderSummaryBadgeProps {
  badgeText?: string | null;
}

const NO_DETAILS_MESSAGE = "Additional Details Unavailable";

const StyledAutoTraderSummaryBadge = styled.span`
  background-color: #e0e0e0;
  border-radius: 15px;
  padding: 0 1em;
  color: #7a7c88;
  font-size: 12px;
  font-weight: 500;
  width: fit-content;
`;

export const AutoTraderSummaryBadge = ({
  badgeText
}: AutoTraderSummaryBadgeProps) => {
  const details = badgeText ?? NO_DETAILS_MESSAGE;
  return <StyledAutoTraderSummaryBadge>{details}</StyledAutoTraderSummaryBadge>;
};

export default AutoTraderSummaryBadge;
