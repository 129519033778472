import { TaskListPage } from "@vinsolutions/page/task-list";
import { RouteBuilder } from "../route-builder";

export const taskListRoute = RouteBuilder.create()
  .withRouteId("vinconnect", "task-list")
  .withFeatureFlag("nx.vinconnect.task-list.enable-test-page")
  //.withAliasId("navigation-sub-menu-tab-crm-tasks") Uncomment before production
  .withLeftPaneComponent(<TaskListPage />)
  .withRightPaneComponent("UserDashboard.aspx")
  .build();
